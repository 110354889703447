<template>
  <div>
    <a-card title="开发详情">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >

      <section id="printContent">
        <a-spin :spinning="loading">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-row>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="number" label="开发编号">
                  <a-input v-model="form.number" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>

          <a-divider orientation="left" style="margin-top: 30px"
            >物料信息</a-divider
          >
          <div>
            <a-row>
              <a-space>
                <a-button
                  type="primary"
                  @click="openMaterialModal_"
                  :disabled="add_button"
                  >添加物料</a-button
                >
              </a-space>
            </a-row>
            <div style="margin-top: 10px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="material_columns"
                :data-source="this.materialItem"
                :pagination="false"
              >
                <div slot="action" slot-scope="value, item, index">
                  <a-button-group
                    size="small"
                    v-if="pending_status.includes(form.status)"
                  >
                    <!-- <a-button-group size="small"> -->
                    <a-button type="danger" @click="removeGood()"
                      >移除</a-button
                    >
                  </a-button-group>
                </div>
              </a-table>
            </div>
          </div>

          <div style="margin-top: 10px">
            <a-row gutter="16">
              <a-space>
                <!-- 添加物料 -->
                <a-button
                  type="primary"
                  @click="openMaterialModal"
                  :disabled="materialItem.length == 0 || add_button"
                  >添加子物料</a-button
                >
              </a-space>
            </a-row>
            <div style="margin-top: 16px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="filteredMaterialsColumns"
                :data-source="materialsData"
                :pagination="false"
              >
                <div
                  slot="material_son_unit_quantity"
                  slot-scope="value, item, index"
                >
                  <div v-if="item.isTotal">{{ value }}</div>
                  <a-input-number
                    v-else
                    v-model="item.material_son_unit_quantity"
                    :min="0"
                    size="small"
                    :disabled="add_button"
                  ></a-input-number>
                </div>

                <div slot="purchase_quantity" slot-scope="value, item, index">
                  <div v-if="item.isTotal">{{ value }}</div>
                  <a-input-number
                    v-else
                    v-model="item.purchase_quantity"
                    :min="0"
                    size="small"
                    :disabled="add_button"
                  ></a-input-number>
                </div>
                <div slot="action" slot-scope="value, item, index">
                  <a-button-group
                    v-if="pending_status.includes(form.status) && !item.isTotal"
                    size="small"
                  >
                    <a-button type="danger" @click="removeMaterial(item)"
                      >移除</a-button
                    >
                  </a-button-group>
                </div>
              </a-table>
            </div>
          </div>
        </a-spin>
      </section>

      <a-divider orientation="left">状态信息</a-divider>
      <div>
        <a-row gutter="16">
          <a-col :span="4">
            <a-form-model-item
              label="开发状态"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-select
                v-model="form.status"
                style="width: 100%"
                @change="checkStatusChange"
              >
                <a-select-option
                  v-for="(label, key) in statusMap"
                  :key="key"
                  :value="key"
                >
                  {{ label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              label="备注"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input v-model="form.remark" style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm title="确保保存吗?" @confirm="update">
          <a-button type="primary" :loading="loading">提交</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <materials-select-modal
      v-model="materialsSelectModalVisible_"
      :warehouse="1"
      @select="onSelectMaterial"
    ></materials-select-modal>
    <materials-select-modal
      v-model="materialsSelectModalVisible"
      :warehouse="1"
      @select="onSelectMaterials"
    ></materials-select-modal>
  </div>
</template>

<script>
import { getMaterialsMDevelopsNumber } from "@/api/data";
import {
  materialsDevelopUpdate,
  materialsDevelopCreate,
  getDevelopRecordDetail,
} from "@/api/materials";

import NP from "number-precision";

export default {
  components: {
    MaterialsSelectModal: () =>
      import("@/components/MaterialSelectModal/index"),
  },

  data() {
    return {
      loading: false,
      category_id: "26", // 类目为26的为连接线类目,有长度属性，需要增加 purchase_quantity 列
      statusMap: {
        concept: "待开发",
        in_development: "开发中",
        pending_review: "等待审核",
        launched: "已发布",
        discontinued: "停产",
        archived: "已归档",
      },
      pending_status: ["concept", "in_development", "pending_review"],

      form: {
        remark: "", // 备注
        status: "concept",
      },
      material_columns: [
        {
          title: "物料名称",
          dataIndex: "materials_name",
          key: "materials_name",
          width: 150,
        },
        {
          title: "物料编号",
          dataIndex: "materials_number",
          key: "materials_number",
          width: 150,
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],

      materials_columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "物料名称",
          dataIndex: "materials_name",
          key: "materials_name",
          width: 150,
        },
        {
          title: "物料编号",
          dataIndex: "materials_number",
          key: "materials_number",
          width: 150,
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
          width: 80,
        },
        // {
        //   title: "所需数量",
        //   dataIndex: "purchase_quantity",
        //   key: "purchase_quantity",
        //   width: 120,
        //   scopedSlots: { customRender: "purchase_quantity" },
        // },
        {
          title: "单位量",
          dataIndex: "material_son_unit_quantity",
          key: "material_son_unit_quantity",
          width: 120,
          scopedSlots: { customRender: "material_son_unit_quantity" },
        },

        {
          title: "采购单价(元)",
          dataIndex: "purchase_price",
          key: "purchase_price",
          width: 120,
        },
        {
          title: "仓库库存",
          dataIndex: "materials_inventory",
          key: "materials_inventory",
          width: 80,
        },

        {
          title: "金额",
          dataIndex: "OneAmount",
          key: "OneAmount",
          width: 80,
          customRender: (value, item) => {
            if (item.isTotal) return value;
            value = NP.times(
              item.purchase_quantity,
              item.purchase_price,
              item.material_son_unit_quantity
            );
            // return item.id ? NP.round(value, 2) : "";
            return NP.round(value, 2);
          },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
      materialItems: [],
      materialItem: [],
      id: this.$route.query.id,
      materialsSelectModalVisible: false,
      materialsSelectModalVisible_: false,
    };
  },

  computed: {
    add_button() {
      const is_pending_status = !this.pending_status.includes(this.form.status);
      return is_pending_status;
      // return false;
    },

    materialsData() {
      let totalQuantity = 0,
        one_amount = 0;
      for (let item of this.materialItems) {
        totalQuantity = NP.plus(totalQuantity, item.purchase_quantity);
        let amount_cost = NP.times(item.purchase_quantity, item.purchase_price);
        one_amount = NP.plus(one_amount, amount_cost);
      }
      return [
        ...this.materialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          purchase_quantity: totalQuantity,
          OneAmount: one_amount,
        },
      ];
    },

    filteredMaterialsColumns() {
      // 需要插入的列数据
      const newColumn = {
        title: "所需数量",
        dataIndex: "purchase_quantity",
        key: "purchase_quantity",
        width: 120,
        scopedSlots: { customRender: "purchase_quantity" },
      };

      // 要去掉的列的 dataIndex
      const columnsToRemove = ["purchase_quantity"];
      const category_list = this.materialItems.map((item) => item.category);

      let materials_columns = [...this.materials_columns];
      // 如果 category_list 中包含 category_id '8'
      if (category_list.includes(this.category_id)) {
        // 在 materials_columns 中找到 'material_son_unit_quantity' 这一列的位置
        const index = this.materials_columns.findIndex(
          (column) => column.dataIndex === "material_son_unit_quantity"
        );

        // 如果找到了该列
        if (index !== -1) {
          // 在该位置前插入新的列（purchase_quantity）
          materials_columns.splice(index, 0, newColumn);
        }
      } else {
        // 如果没有 category_id，过滤掉指定的列
        materials_columns = materials_columns.filter(
          (column) => !columnsToRemove.includes(column.dataIndex)
        );
      }

      // 返回处理后的列
      return materials_columns;
    },
  },
  methods: {
    initData() {
      if (this.id) {
        this.getDetail();
      } else {
        this.resetForm();
      }
    },

    init_data(res) {
      // 初始化表单数据
      this.form = {
        id: res.id,
        number: res.number,
        status: res.status,
        remark: res.remark,
      };

      // 初始化物料数据
      this.materialItem = [
        {
          id: res.id,
          materials: res.material,
          materials_name: res.materials_name,
          materials_number: res.materials_number,
        },
      ];

      // 优化物料列表数据处理
      this.materialItems = res.materials_dev_items.map((item) => {
        const inventory_item = item.materials_inventory?.[0] || {}; // 使用可选链操作符

        return {
          id: item.id,
          material_son: item.material_son,
          purchase_quantity: item.material_son_quantity || 0, // 如果没有数量，默认0
          material_son_unit_quantity: item.material_son_unit_quantity || 0, // 如果没有数量，默认0
          category: inventory_item.category,
          materials_number: inventory_item.materials_number || "", // 默认空字符串
          materials_name: inventory_item.materials_name || "",
          materials_spec: inventory_item.materials_spec || "",
          unit_name: inventory_item.unit_name || "",
          purchase_price: inventory_item.purchase_price || 0, // 默认0
          materials_inventory: inventory_item.total_quantity || 0, // 默认0
        };
      });
    },

    getDetail() {
      this.loading = true;
      // 网络请求获取数据
      getDevelopRecordDetail(this.id)
        .then((res) => {
          this.init_data(res);
        })
        .catch((error) => {
          // 错误处理，确保用户能看到错误信息
          console.error("Failed to fetch data:", error);
          this.$message.error("加载数据失败，请稍后重试。");
        })
        .finally(() => {
          // 请求完成，设置加载状态
          this.loading = false;
        });
    },

    // 添加物料
    openMaterialModal() {
      this.materialsSelectModalVisible = true;
    },
    openMaterialModal_() {
      this.materialsSelectModalVisible_ = true;
    },

    onSelectMaterials(item) {
      let index = this.materialItems.findIndex(
        (_item) => _item.material_son == item.id
      );
      if (index != -1 && item.category != this.category_id) {
        this.$message.warn("物料已存在"); //物料属性为端子线的允许重复添加
        return;
      }
      this.materialItems = this.$functions.insertItemJ(this.materialItems, {
        id: item.id,
        material_son: item.materials,
        materials_number: item.materials_number,
        materials_name: item.materials_name,
        materials_spec: item.materials_spec,
        category: item.category,
        unit_name: item.unit_name,
        purchase_quantity: 1,
        material_son_unit_quantity: 1,
        purchase_price: item.purchase_price,
        materials_inventory: item.total_quantity,
      });
    },

    onSelectMaterial(item) {
      console.log(item);
      this.materialItem = [
        {
          id: item.id,
          materials: item.materials,
          materials_name: item.materials_name,
          materials_number: item.materials_number,
        },
      ];
    },
    removeGood() {
      this.materialItem = [];
      this.materialItems = [];
    },
    removeMaterial(item) {
      this.materialItems = this.$functions.removeItem(this.materialItems, item);
    },

    resetForm() {
      getMaterialsMDevelopsNumber().then((data) => {
        this.form = { ...this.form, number: data.number };
      });
    },
    hasDuplicate(items) {
      // 使用 Set 来存储每个组合的键，如果出现相同的键则说明有重复项
      const seen = new Set();

      // 遍历数组，判断是否有重复项
      for (const item of items) {
        const key = `${item.material_son}-${item.material_son_unit_quantity}-${item.purchase_quantity}`;
        if (seen.has(key)) {
          // 如果已经出现过这个组合键，说明有重复项
          return true;
        }
        seen.add(key); // 否则，添加该组合键
      }

      // 如果没有重复项
      return false;
    },

    update() {
      console.log("this.materialItems", this.materialItems);
      let materials_items = this.materialItems.map((item) => {
        return {
          id: item.id,
          material_son: item.material_son,
          material_son_quantity: item.purchase_quantity,
          material_son_unit_quantity: item.material_son_unit_quantity,
        };
      });
      const is_unique_data = this.hasDuplicate(materials_items);
      if (is_unique_data) {
        return this.$message.error("数据重复");
      }
      console.log("materials_items", materials_items);
      let formData = {
        ...this.form,
        material: this.materialItem[0].materials,
        materials_dev_items: materials_items,
        // materials_relationships:materials_items_id
      };

      console.log("formData", formData);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id
            ? materialsDevelopUpdate
            : materialsDevelopCreate;
          console.log("formData_", formData);
          func(formData)
            .then((data) => {
              this.init_data(data);
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
            })
            .catch((err) => {
              // 判断错误是否有响应对象
              if (err.response) {
                // 处理包含错误的响应数据
                console.log("err.response.data:", err.response.data);
                const errorMessage = JSON.stringify(err.response.data, null, 2); // `null, 2` 用于美化格式，2表示缩进为2个空格
                this.$message.error(errorMessage);
              } else {
                // 处理其他类型的错误（例如网络错误）
                this.$message.error("请求错误");
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },

  mounted() {
    console.log("Hello");
    this.initData();
  },
};
</script>


<style></style>